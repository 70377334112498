<template>
  <div class="template-items-modal">
    <base-modal-ex :active.sync="active"
      :animation-in="animationIn"
      :animation-out="animationOut"
      :animation-duration="animationDuration"
      :can-cancel="false"
      has-modal-card>
      <div class="model-content">

        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Choose Items from: {{ selectedTemplate.name }}
            </p>
          </header>

          <section class="modal-card-body">

            <div v-if="isLoading"
              class="spinner-container">
              <spinner />
            </div>

            <div v-else>

              <div class="is-flex border-bottom-primary">
                <div class="is-size-4">Items</div>
              </div>

              <div class="is-flex mt-4">
                <div class="">
                  <div class="pretty p-icon p-curve p-smooth p-bigger">
                    <input type="checkbox"
                      :checked="areAllSelected"
                      @click="handleSelectAll"
                      :id="'quote-template-items-checkbox-all-items'"
                      :name="'quote-template-items-checkbox-all-items'">
                    <div class="state">
                      <i class="icon mdi mdi-check" />
                      <label />
                    </div>
                  </div>
                </div>
                <span class="mr-2">
                  Select All
                </span>
              </div>

              <div class="template-items-container">

                <div v-for="(data, index) in sortedData"
                  :key="data.name + index">

                  <div class="template-item-grid is-title mt-5 has-background-white pb-2 mb-2 has-text-weight-semibold">
                    <div />
                    <div class="">{{ data.name }} ({{ data.length }})</div>
                    <div>Rate</div>
                    <div>Rpt Only</div>
                    <div />
                  </div>

                  <!-- horizontal break -->
                  <!-- <div class="is-divider my-1 mb-3" /> -->

                  <!-- template Items -->
                  <div>
                    <div class="template-item-grid is-size-6 mb-1"
                      v-for="(item, indexchild) in data.items"
                      :key="index + indexchild"
                      :value="item">

                      <div class="pretty p-icon p-curve p-smooth p-bigger mt-4">
                        <input type="checkbox"
                          :checked="isItemSelected(item)"
                          @click="handleItemClick(item)"
                          :class="true ? 'is-primary' : ''"
                          :id="'quote-template-items-checkbox-child' + index + indexchild"
                          :name="'quote-template-items-checkbox-child' + index + indexchild">
                        <div class="state">
                          <i class="icon mdi mdi-check" />
                          <label />
                        </div>
                      </div>

                      <div class="ml-2"> {{ item.itemDesc }}</div>
                      <div> {{ $filters.formatCurrency(data.rate, $userInfo.locale) }} </div>
                      <div class=""> {{ item.reportOnly ? 'Yes' : 'No' }}</div>

                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- <section class="modal-card-body">

            <div v-if="finishFetchingTemplates && !isTemplateListNonEmpty"
            class="field">
            <span class="has-text-danger is-italic">
              <i class="mdi mdi-alert-outline" />
              No templates available. Please contact your admin to create a new template under
              <span class="has-text-weight-bold">Quote > Quote Template.</span>
            </span>
          </div>
          <div class="field">
            <label class="label">{{ label }}</label>
            <div class="select is-fullwidth">
              <select v-model="selectedTemplate"
              :disabled="finishFetchingTemplates && !isTemplateListNonEmpty">
              <option value="null"
              disabled>Select</option>
              <option v-for="(template, index) in templates"
              :key="index"
              :value="template">{{ template.name }}</option>
            </select>
          </div>
        </div>
      </section> -->
          </section>
          <footer class="modal-card-foot"
            :style="`justify-content: flex-end`">
            <button class="button"
              @click="close">Cancel</button>
            <button class="button is-primary"
              @click="handleAddTemplateItems"
              :disabled="!isTemplateSelected">Add to Quote</button>
          </footer>
        </div>
      </div>
    </base-modal-ex>
  </div>
</template>

<script>
import BaseModalEx from '@/components/BulmaModal/BaseModalEx'
import QuoteTemplateService from '@/services/QuoteTemplateService'
import Spinner from '../../../../components/Spinners/Spinner.vue'

export default {
  name: 'TemplateItemsModal',
  components: {
    BaseModalEx,
    Spinner
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    selectedTemplate: {
      type: Object,
      default: null
    },
    rates: {
      type: Array,
      required: true
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: Number,
      default: 0.3
    },
    label: {
      type: String,
      default: 'Select Template'
    },
    vehcile: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      templateItems: [],
      isActive: this.active || false,
      sortOrder: [
        { name: 'Remove & Refit', key: 'RR' },
        { name: 'Repair', key: 'REP' },
        { name: 'Paint', key: 'PAINT' },
        { name: 'Mechanical', key: 'MECH' },
        { name: 'Sublet', key: 'SUBL' },
        { name: 'MISC', key: 'MISC' }
      ],
      sortedData: [],
      selectedItems: []
    }
  },
  computed: {
    isTemplateSelected() {
      return this.selectedTemplate !== null
    },
    areAllSelected() {
      return this.templateItems.length === this.selectedItems.length
    }
  },

  mounted() {
    this.getTemplateItems()
  },

  methods: {
    getSortedData() {
      this.sortedData = this.sortOrder.map((order) => {
        const items = this.templateItems.filter((item) => item.itemType === order.key)
        return {
          key: order.key,
          name: order.name,
          items,
          length: items.length,
          rate: this.rates.find((rate) => rate.labourCodeId === order.key)?.rate
        }
      })
    },

    getRate(key) {
      return this.rates.find((rate) => rate.labourCodeId === order.key)?.rate
    },

    handleSelectAll() {
      if (this.areAllSelected) {
        this.selectedItems = []
      } else {
        this.selectedItems = this.templateItems.map((item) => item.itemId)
      }
    },
    handleItemClick(item) {
      // if item is not in selectedItems, add it
      if (!this.selectedItems.includes(item.itemId)) {
        this.selectedItems.push(item.itemId)
      } else {
        // if item is in selectedItems, remove it
        const index = this.selectedItems.indexOf(item.itemId)
        this.selectedItems.splice(index, 1)
      }
    },
    isItemSelected(item) {
      return this.selectedItems.includes(item.itemId)
    },

    async getTemplateItems() {
      this.isLoading = true
      const id = this.selectedTemplate.templateId
      if (!id) return
      const templateItems = await QuoteTemplateService.getSortedQuoteTemplateItems(id)
      this.templateItems = templateItems
      this.isLoading = false

      this.getSortedData()

      this.selectedItems = templateItems.map((item) => item.itemId)
    },
    close() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    },
    handleAddTemplateItems() {
      let labourItems = []
      let miscItems = []
      let otherItems = []
      let subletItems = []

      this.selectedItems.forEach((itemId) => {
        const item = this.templateItems.find((item) => item.itemId === itemId)
        if (item.itemType === 'RR' || item.itemType === 'REP' || item.itemType === 'PAINT') {
          labourItems.push(item)
        } else if (item.itemType === 'MISC') {
          miscItems.push(item)
        } else if (item.itemType === 'SUBL') {
          subletItems.push(item)
        } else {
          otherItems.push(item)
        }
      })
      const obj = {
        labourItems,
        miscItems,
        otherItems,
        subletItems
      }
      this.$emit('addTemplateItemsCustom', obj)
      this.close()
    },
    async getNtarTimesForLabourItems(labourItems) {
      // const labourItemsForNtar = await

      return []
    }
  }
}
</script>

<style scoped>
.template-item-grid {
  display: grid;
  grid-template-columns: 3% 71% 13% 13%;
}
</style>

<style lang="scss">
@import '@/assets/style/themes/default/_variables.scss';

.border-bottom-primary {
  border-bottom: 1px solid $primary;
}

.template-items-modal,
.modal-card {
  min-width: 800px;
  width: 60vw;
}

.template-items-container {
  overflow-y: auto;
  max-height: 50vh;
}

.template-item-grid {
  // grid-gap: 1rem;

  &.is-title {
    position: sticky;
    top: -1px;
    border-bottom: 1px;
    z-index: 3;
    border-bottom: 1px solid rgba($grey, 0.3);
  }
}
</style>