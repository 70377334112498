import { render, staticRenderFns } from "./TemplateComponent.vue?vue&type=template&id=2c30cd94&scoped=true"
import script from "./TemplateComponent.vue?vue&type=script&lang=js"
export * from "./TemplateComponent.vue?vue&type=script&lang=js"
import style0 from "./TemplateComponent.vue?vue&type=style&index=0&id=2c30cd94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c30cd94",
  null
  
)

export default component.exports