<template>
  <div>
    <!-- Your component content goes here -->
    <!---------------------- Available parts section  -------------->
    <available-parts v-if="isAvailableItemsActive && avaliableItemsType === 'PARTS'"
      v-model="innerValue.parts"
      :quote-id="value.quoteId"
      :quote-no="value.quoteNo"
      :sub-quote-no="value.subQuoteNo"
      :page-size="1200"
      :sub-quote-nos="value.subQuoteNos"
      :is-audanet="value.audatex !== null"
      :next-line-number="nextLineNumber"
      :show-scroll-to-add="false"
      :vehicle-id="vehicle ? vehicle.id : ''"
      @item-added="onItemAddedPart" />
    <!--------------------- Supp Quote Main section  ------------------>
    <available-parts-main v-if="isMainActive && avaliableItemsType === 'PARTS'"
      v-model="innerValue.parts"
      :quote-id="value.quoteId"
      :quote-no="value.quoteNo"
      :page-size="1200"
      :sub-quote-no="value.subQuoteNo"
      :next-line-number="nextLineNumber"
      @item-added="onItemAddedPart" />

    <!---------------------- Available labour section  -------------->
    <available-labours v-if="isAvailableItemsActive && avaliableItemsType === 'LABOUR'"
      v-model="innerValue.labours"
      :quote-id="value.quoteId"
      :next-line-number="nextLineNumber"
      :rr-rate="getLabourRate(itemCategoryTypes.RR, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :rwa-rate="getLabourRate(itemCategoryTypes.RWA, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :repair-rate="getLabourRate(itemCategoryTypes.REP, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :paint-rate="getLabourRate(vehicle.paintGroup, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :shop-rate="value.shopRate"
      :vehicle-paint-group="vehicle.paintGroup"
      :quoting-method="value.quotingMethod"
      :page-size="1200"
      :schedule-id="vehicle.scheduleId"
      :body-id="vehicle.bodyId"
      :make="vehicle.model.make"
      :model="vehicle.model.model"
      :year="vehicleBuildYear"
      :labour-type="value.labourType"
      :is-ntar="isNtar"
      :show-scroll-on-add="false"
      @item-added="onItemAddedLabour" />
    <!------------------- Supplementary quote Main section  -------------->
    <available-labours-main v-if="isMainActive && avaliableItemsType === 'LABOUR'"
      v-model="innerValue.labours"
      :quote-id="value.quoteId"
      :next-line-number="nextLineNumber"
      :quote-no="value.quoteNo"
      :sub-quote-no="value.subQuoteNo"
      :rr-rate="getLabourRate(itemCategoryTypes.RR, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :rwa-rate="getLabourRate(itemCategoryTypes.RWA, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :repair-rate="getLabourRate(itemCategoryTypes.REP, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :paint-rate="getLabourRate(vehicle.paintGroup, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :vehicle-paint-group="vehicle.paintGroup"
      :quoting-method="value.quotingMethod"
      :page-size="1200"
      :schedule-id="vehicle.scheduleId"
      :body-id="vehicle.bodyId"
      :make="vehicle.model.make"
      :model="vehicle.model.model"
      :year="vehicleBuildYear"
      :labour-type="innerValue.labourType"
      @item-added="onItemAddedLabour" />

    <!--------------------- Available other labour  ------------------>
    <available-other-labours v-if="isAvailableItemsActive && avaliableItemsType === 'OTHER'"
      v-model="innerValue.others"
      :quote-id="value.quoteId"
      :next-line-number="nextLineNumber"
      :quoting-method="value.quotingMethod"
      :page-size="1200"
      :fg-rate="getLabourRate(itemCategoryTypes.FIBER, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :crush-rate="getLabourRate(itemCategoryTypes.CRUSH, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :cd-rate="getLabourRate(itemCategoryTypes.CD, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :mech-rate="getLabourRate(itemCategoryTypes.MECH, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :shop-rate="value.shopRate"
      :is-ntar="isNtar"
      @item-added="onItemAddedOther" />
    <!------------------- Supplementary quote Main section  -------------->
    <available-other-labours-main v-if="isMainActive && avaliableItemsType === 'OTHER'"
      v-model="innerValue.others"
      :quote-id="value.quoteId"
      :quote-no="value.quoteNo"
      :sub-quote-no="value.subQuoteNo"
      :next-line-number="nextLineNumber"
      :quoting-method="value.quotingMethod"
      :page-size="1200"
      :fg-rate="getLabourRate(itemCategoryTypes.FIBER, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :crush-rate="getLabourRate(itemCategoryTypes.CRUSH, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :cd-rate="getLabourRate(itemCategoryTypes.CD, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      :mech-rate="getLabourRate(itemCategoryTypes.MECH, innerValue.rates, innerValue.shopRate, innerValue.quoteId).rate"
      @item-added="onItemAddedOther" />

    <!-------------------- Available Misc Section  ------------>
    <available-miscs v-if="isAvailableItemsActive && avaliableItemsType === 'MISC'"
      v-model="innerValue.miscs"
      :quote-id="value.quoteId"
      :labour-type="value.labourType"
      :next-line-number="nextLineNumber"
      :page-size="1200"
      @item-added="onItemAddedMisc" />

    <!---------------- Supplementry quote misc main section  -------->
    <available-miscs-main v-if="isMainActive && avaliableItemsType === 'MISC'"
      v-model="innerValue.miscs"
      :quote-id="value.quoteId"
      :quote-no="value.quoteNo"
      :sub-quote-no="value.subQuoteNo"
      :labour-type="value.labourType"
      :next-line-number="nextLineNumber"
      :page-size="1200"
      @item-added="onItemAddedMisc" />

    <!---------------------Available Sublet Section ----------->
    <available-sublets v-if="isAvailableItemsActive && avaliableItemsType === 'SUBLETS'"
      v-model="innerValue.sublets"
      :quote-id="value.quoteId"
      :labour-type="value.labourType"
      :next-line-number="nextLineNumber"
      :is-audanet="value.audatex !== null"
      :page-size="1200"
      @item-added="onItemAddedSublet" />

    <!---------------- Supplementry quote sublets main section  -------->
    <available-sublets-main v-if="isMainActive && avaliableItemsType === 'SUBLETS'"
      v-model="innerValue.sublets"
      :quote-id="value.quoteId"
      :quote-no="value.quoteNo"
      :sub-quote-no="value.subQuoteNo"
      :labour-type="value.labourType"
      :next-line-number="nextLineNumber"
      :page-size="1200"
      @item-added="onItemAddedSublet" />

  </div>
</template>

<script>
import {
  AvailableParts,
  AvailablePartsMain,
  AvailableLabours,
  AvailableLaboursMain,
  AvailableMiscs,
  AvailableMiscsMain,
  AvailableSublets,
  AvailableSubletsMain,
  AvailableOtherLabours,
  AvailableOtherLaboursMain
} from '@/views/quote/components'
import { LabourTimeTypes, ItemCategoryTypes } from '@/enums'
import { QuoteLabourMixin } from '../mixins'
import isEmpty from 'lodash.isempty'
import cloneDeep from 'lodash.clonedeep'
import deepDiff from 'deep-diff'

export default {
  name: 'AddItemsComponent',
  components: {
    AvailableParts,
    AvailableLabours,
    AvailableLaboursMain,
    AvailableMiscs,
    AvailableMiscsMain,
    AvailableSublets,
    AvailableSubletsMain,
    AvailableOtherLabours,
    AvailablePartsMain,
    AvailableOtherLaboursMain
  },
  mixins: [QuoteLabourMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    vehicle: {
      type: Object,
      required: true
    },

    isAvailableItemsActive: {
      type: Boolean,
      required: true
    },
    isMainActive: {
      type: Boolean,
      required: true
    },
    avaliableItemsType: {
      type: String,
      required: true
    },
    nextLineNumber: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      innerValue: null
    }
  },
  computed: {
    isAvailableItemsMainActive() {
      return false
    },
    vehicleBuildYear() {
      return this.vehicle.buildYear ? Number(this.vehicle.buildYear) : this.vehicle.model.startYear ? Number(this.vehicle.model.startYear) : 0
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    LabourTimeTypes() {
      return LabourTimeTypes
    },
    isNtar() {
      return (
        this.innerValue.labourType === LabourTimeTypes.NTAR ||
        this.innerValue.labourType === LabourTimeTypes.LTAR ||
        this.innerValue.labourType === LabourTimeTypes.eMTA
      )
    },
    isAudanet() {
      return this.value.isAudanet
    }
  },
  watch: {
    innerValue: {
      handler() {
        this.$emit('handleUpdate', cloneDeep(this.innerValue))
      },
      deep: true
    },
    value: {
      handler(newValue) {
        if (deepDiff(this.innerValue, newValue)) {
          this.innerValue = cloneDeep(newValue)
        }
      },
      deep: true
    }
  },
  created() {
    this.innerValue = cloneDeep(this.value)
  },
  methods: {
    onItemAddedPart(newItem) {
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.parts.splice(this.innerValue.parts.length, 1, newItem)

      this.$notification.openNotificationWithType('success', '', 'Part added')
      this.$eventHub.$emit('itemAddedButterfly', newItem.quoteItemId)
    },
    onItemAddedLabour(newItems) {
      if (!isEmpty(newItems)) {
        const vm = this
        newItems.forEach(function (item) {
          vm.innerValue.labours.splice(vm.innerValue.labours.length, 1, item)
          if (vm.isNtar && item.hourValue > 0 && (item.itemType === ItemCategoryTypes.REP || item.itemType === ItemCategoryTypes.PAINT)) {
            vm.addUpdateRepairPaintLoading(item)
          }
        })
        this.innerValue.lines = Math.max(...newItems.map((i) => i.lineNumber), 0) + 1

        this.$notification.openNotificationWithType('success', '', `Labour Item${newItems.length > 1 ? 's' : ''} added`)
      }
      this.$eventHub.$emit('itemAddedButterfly', newItems[0].quoteItemId)
    },
    onItemAddedMisc(newItem) {
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.miscs.splice(this.innerValue.miscs.length, 1, newItem)

      this.$notification.openNotificationWithType('success', '', 'Miscallaneaous Item added')
      this.$eventHub.$emit('itemAddedButterfly', newItem.quoteItemId)
    },
    onItemAddedSublet(newItem) {
      this.innerValue.lines = newItem.lineNumber + 1
      this.innerValue.sublets.splice(this.innerValue.sublets.length, 1, newItem)

      this.$notification.openNotificationWithType('success', '', 'Item added')
      this.$eventHub.$emit('itemAddedButterfly', newItem.quoteItemId)
    },
    onItemAddedOther(newItems) {
      if (!isEmpty(newItems)) {
        const vm = this
        newItems.forEach(function (item) {
          vm.innerValue.others.splice(vm.innerValue.others.length, 1, item)
        })
        this.innerValue.lines = Math.max(...newItems.map((i) => i.lineNumber), 0) + 1

        this.$notification.openNotificationWithType('success', '', `Item${newItems.length > 1 ? 's' : ''} added`)
        this.$eventHub.$emit('itemAddedButterfly', newItems[0].quoteItemId)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/themes/default/variables';

.table-container-avaialble-butterfly-items {
  max-height: 65vh;
  overflow-y: auto;

  /* remove border from table body and headings */
  td {
    border: none;
  }

  thead {
    tr {
      z-index: 9;
      position: sticky;
      top: 0;
      background: white;
    }
  }

  tfoot {
    display: none;
  }

  .butterfly-item-values {
    cursor: pointer;

    border: 1px solid $grey;
    border-radius: 5px;
    align-self: center;

    &.is-selected {
      color: white;
      background-color: $primary;
      border: $primary 1px solid;
    }

    &:hover {
      color: $primary;
      border: $primary 1px solid;
    }

    &.is-selected:hover {
      color: white;
      border: $danger 1px solid;
    }
  }
}
</style>