<template>
  <div class="template-items-modal">
    <base-modal-ex :active.sync="active"
      :animation-in="animationIn"
      :animation-out="animationOut"
      :animation-duration="animationDuration"
      :can-cancel="false"
      has-modal-card>
      <div class="model-content">

        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Create a New Template
            </p>
          </header>

          <section class="modal-card-body">

            <div v-if="isLoading || isSaving"
              class="spinner-container">
              <spinner :message=" isLoading ? 'Loading..' : 'Saving...'" />
            </div>

            <div>

              <div class="field">
                <label class="label">Template Name</label>
                <div class="control">
                  <input class="input"
                    :class="{'is-danger': !templateName || templateName == '' || !isTemplateNameValid}"
                    type="text"
                    placeholder="Enter Quote Template name"
                    v-model="templateName">
                </div>
              </div>

              <div class="tags"
                v-if="!isTemplateNameValid">
                <span class="tag is-danger">
                  Name already in use, chose a different name
                </span>
              </div>

              <div class="category-item-grid mb-5 mt-5 pb-2">
                <div>Select Categories</div>

                <div class=" is-flex is-justify-content-end">
                  <div class="is-flex is-justify-content-end">
                    <span class="mr-2">
                      Select All
                    </span>
                  </div>
                  <div class="">
                    <div class="pretty p-icon p-curve p-smooth p-bigger">
                      <input type="checkbox"
                        @click="selectAll(!areAllSelected)"
                        :checked="areAllSelected"
                        :id="'quote-template-items-checkbox-all-items'"
                        :name="'quote-template-items-checkbox-all-items'">
                      <div class="state">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div v-for="(type, index) in dataTypes"
                  :key="type.key + index"
                  class="category-item-grid mb-2 pb-2">
                  <div class="is-flex">
                    <span class="mr-2">
                      {{ type.title }}
                    </span>
                    <div class="item-count">{{ itemLengths[type.key] }}</div>
                  </div>

                  <div class="is-flex is-align-self-center is-justify-content-end">
                    <div class="pretty p-icon p-curve p-smooth p-bigger">
                      <input type="checkbox"
                        :checked="isSelected(type)"
                        @click="selectItems(type)"
                        :id="'quote-template-items-checkbox-all-items'"
                        :name="'quote-template-items-checkbox-all-items'">
                      <div class="state">
                        <i class="icon mdi mdi-check" />
                        <label />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>

          <footer class="modal-card-foot"
            :style="`justify-content: flex-end`">
            <button class="button"
              @click="close">Cancel</button>
            <button class="button is-primary"
              @click="createNewTemplate"
              :disabled="isDisabled">Save Template</button>
          </footer>

        </div>
      </div>
    </base-modal-ex>
  </div>
</template>

<script>
import BaseModalEx from '@/components/BulmaModal/BaseModalEx'
import Spinner from '../../../../components/Spinners/Spinner.vue'
import { QuoteTemplateModel } from '@/classes/viewmodels'
import { QuoteTemplateItemModel } from '../../../../classes/viewmodels'
import QuoteTemplateService from '../../../../services/QuoteTemplateService'
import Guid from '../../../../components/Guid'

import { ItemCategoryTypes } from '@/enums'
import { debounce } from 'lodash'

export default {
  name: 'SaveTemplateModal',
  components: {
    BaseModalEx,
    Spinner
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    dataTypes: {
      type: Array,
      default: () => []
    },
    quote: {
      type: Object,
      required: true
    },
    vehicle: {
      type: Object,
      required: true
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: Number,
      default: 0.3
    },
    label: {
      type: String,
      default: 'Select Template'
    }
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      templateItems: [],
      isActive: this.active || false,

      templateName: '',
      selectedItems: [],

      isTemplateNameValid: true
    }
  },
  computed: {
    areAllSelected() {
      return this.selectedItems.length == this.dataTypes.length
    },
    isDisabled() {
      return !this.selectedItems.length || this.isLoading || this.isSaving || !this.templateName || this.templateName == ''
    },
    rrItems() {
      return this.quote.labours.filter((i) => i.itemType === ItemCategoryTypes.RR && !i.isDeleted)
    },
    repItems() {
      return this.quote.labours.filter((i) => i.itemType === ItemCategoryTypes.REP && !i.isDeleted)
    },
    paintItems() {
      return this.quote.labours.filter((i) => i.itemType === ItemCategoryTypes.PAINT && !i.isDeleted)
    },
    partItems() {
      return this.quote.parts.filter((p) => !p.isDeleted)
    },
    mechItems() {
      return this.quote.others.filter((i) => !i.isDeleted && i.itemType == ItemCategoryTypes.MECH)
    },
    subletItems() {
      return this.quote.sublets.filter((i) => !i.isDeleted)
    },
    miscItems() {
      return this.quote.miscs.filter((i) => !i.isDeleted)
    },
    itemLengths() {
      return {
        rr: this.rrItems.length,
        rep: this.repItems.length,
        paint: this.paintItems.length,
        part: this.partItems.length,
        mech: this.mechItems.length,
        sublet: this.subletItems.length,
        misc: this.miscItems.length
      }
    }
  },
  watch: {
    templateName: {
      handler: debounce(function (val) {
        if (val !== '' && val !== null) {
          this.getTemplateValidity()
        }
      }, 500),
      immediate: false
    }
  },

  methods: {
    async getTemplateValidity() {
      const templateNameExists = await QuoteTemplateService.CheckTemplateNameValidity(this.templateName, this.$company.info.companyId)
      this.isTemplateNameValid = !templateNameExists
    },
    close() {
      this.isActive = false
      this.$emit('update:active', false)
    },
    isSelected(type) {
      return this.selectedItems.includes(type.key)
    },
    selectItems(type) {
      // if type.key is in selectedItems, remove it
      // else add it
      if (this.selectedItems.includes(type.key)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== type.key)
      } else {
        this.selectedItems.push(type.key)
      }
    },
    selectAll(value) {
      if (value) {
        this.selectedItems = this.dataTypes.map((type) => type.key)
      } else {
        this.selectedItems = []
      }
    },
    async createNewTemplate() {
      this.isSaving = true

      const templateObj = new QuoteTemplateModel()

      templateObj.templateId = Guid.empty()
      templateObj.bodyId = this.vehicle.bodyId
      templateObj.modelId = this.vehicle.model.id
      templateObj.name = this.templateName
      templateObj.companyId = this.$company.info.companyId

      templateObj.quoteType = this.quote.quoteType
      templateObj.timesType = this.quote.labourType

      console.log('templateObj', templateObj)

      const quoteItems = this.dataTypes.reduce((acc, type) => {
        if (this.selectedItems.includes(type.key)) {
          if (type.key === 'rr') {
            acc.push(...this.rrItems)
          } else if (type.key === 'rep') {
            acc.push(...this.repItems)
          } else if (type.key === 'paint') {
            acc.push(...this.paintItems)
          } else if (type.key === 'part') {
            acc.push(...this.partItems)
          } else if (type.key === 'mech') {
            acc.push(...this.mechItems)
          } else if (type.key === 'sublet') {
            acc.push(...this.subletItems)
          } else if (type.key === 'misc') {
            acc.push(...this.miscItems)
          }
        }
        return acc
      }, [])

      const templateItems = quoteItems.map((item) => {
        const quoteItem = new QuoteTemplateItemModel(item)
        // should this not be in the model ?
        quoteItem.tempateId = Guid.empty()
        quoteItem.itemId = Guid.empty()
        quoteItem.templateItemId = item.quoteItemId
        return quoteItem
      })

      await QuoteTemplateService.createNewTemplateWithItems(templateObj, templateItems)

      this.isSaving = false
      // show toadt
      this.$notification.success('Template', 'New Template Created')
      this.close()
    }
  }
}
</script>

<style scoped>
.template-item-grid {
  display: grid;
  grid-template-columns: 63% 13% 13% 11%;
}
</style>

<style  scoped lang="scss">
@import '@/assets/style/themes/default/variables';

.template-items-modal,
.modal-card {
  min-width: 800px;
  width: 60vw;
}

.template-items-container {
  overflow-y: auto;
  max-height: 50vh;
}

.category-item-grid {
  display: grid;
  grid-template-columns: 80% 20%;
  // grid-gap: 1rem;
  border-bottom: 2px solid #cfcbcb;

  .item-count {
    background: $primary;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>